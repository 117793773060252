import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule, Location } from '@angular/common';
import { RouterModule } from '@angular/router';
import { environment } from 'environments/environment';
import { SharedComponentModule } from '@shared/components/shared-component.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PwaService } from '@shared/service/app/pwa.service';

@Component({
  selector: 'app-reporte',
  standalone: true,
  imports: [CommonModule, RouterModule, SharedComponentModule, MatIconModule, MatTooltipModule],
  templateUrl: './reporte.component.html'
})
export class ReporteComponent implements OnInit {
  reporteUrl: string;
  isActive = false;

  constructor(
    private route: ActivatedRoute, 
    private pwaService: PwaService,
    private location: Location
  ) 
  {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const path = params.get('path');
      if (path) {
        if (path.startsWith('http://') || path.startsWith('https://')) {
          this.reporteUrl = path.replace(/^http:\/\//i, 'https://');
        } else {
          this.reporteUrl = `${environment.baseRest}/${path}`;
        }
      } else {
        console.error('No se proporcionó el camino del reporte');
      }
    });
  }

  goBack() {
    this.location.back();
    this.isActive = true;
  }
}