<div
  class="flex flex-center w-full h-full flex-col items-center justify-center min-h-full"
>
  <img src="assets/images/spinner/spinner-bauen.gif" class="w-10 mt-10" />
  <div class="spinner mt-8 mb-4">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
  <span class="font-semibold text-grey-700 text-lg mt-2">{{ message }}</span>
</div>
