
export const TitleType = [
  // Inicio
  { icon: "", text: "Inicio", path: "/inicio" },
  // Complejo
  { icon:"", text: "Complejo", path:"/complejo" },
  // Contratos
  { icon: "", text: "Contratos", path: "/contratos" },
  // Indices
  { icon: "", text: "Valor de Índices Histórico", path: "/indices" },
  // Expensas
  { icon: "", text: "Expensas", path: "/expensas" },
  // Filtros
  { icon: "", text: "Disponibles", path: "/disponibles" },
  { icon: "", text: "Próximo a Vencer", path: "/proximo-vencer" },
  // Reservas
  { icon: "", text: "Reservas", path: "/reservas" },
  { icon: "", text: "Pedido de Reserva", path: "/reservas/pedido-reserva" },
  { icon: "", text: "Pedido de Reserva", path: "/reservas/reserva-pedido" },
  // Deudas
  { icon: "", text: "Deudas Por Inquilino", path: "/por-inquilino" },
  { icon: "", text: "Deudas Por Período", path: "/por-periodo" },
  { icon: "", text: "Deudas Por Complejo", path: "/por-complejo" },
  { icon: "", text: "Detalle de Deuda", path: "/detalles-deuda" },
  { icon: "", text: "Incobrables", path: "/incobrables" },
  // Dashboard
  { icon: "", text: "Dashboard", path: "/dashboard" },
  { icon: "", text: "Nueva Captación", path: "/dashboard/nueva-captacion" },
  { icon: "", text: "Editar Captación", path: "/dashboard/editar-captacion" },
  { icon: "", text: "Nueva Demanda", path: "/dashboard/nueva-demanda" },
  { icon: "", text: "Editar Demanda", path: "/dashboard/editar-demanda" },
  { icon: "", text: "Captaciones Pendientes", path: "/dashboard/captaciones-pendientes" },
  { icon: "", text: "Captaciones en Proceso", path: "/dashboard/captaciones-en-proceso" },
  { icon: "", text: "Captaciones Pendientes de Aprobación", path: "/dashboard/captaciones-pendientes-aprobacion" },
  { icon: "", text: "Propiedades Captadas", path: "/dashboard/propiedades-captadas" },
  { icon: "", text: "Perdido Captaciones", path: "/dashboard/perdido-captaciones" },
  { icon: "", text: "Oportunidades Demanda", path: "/dashboard/oportunidades-demanda" },
  { icon: "", text: "Demanda Captada", path: "/dashboard/demanda-captada" },
  { icon: "", text: "Perdido Consultas", path: "/dashboard/perdido-consultas" },
  { icon: "", text: "Operaciones en Proceso", path: "/dashboard/operaciones-en-proceso" },
  { icon: "", text: "Operaciones con Reserva", path: "/dashboard/operaciones-con-reserva" },
  { icon: "", text: "Operaciones Concretadas", path: "/dashboard/operaciones-concretadas" },
  { icon: "", text: "Operaciones Perdidas", path: "/dashboard/operaciones-perdidas" },
  // Valor de Alquilere
  { icon: "", text: "Valor de Alquiler", path: "/valor-alquileres"},
  { icon: "", text: "Valor de Alquiler", path: "/valor-alquileres/detalle/alquiler"},
  // Búsquedas
  { icon: "", text: "Búsquedas", path: "/busquedas"}
];